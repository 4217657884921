<template>
  <section id="about">
      <v-sheet
        :height="$vuetify.breakpoint.smAndUp ? '50vh' : '75vh'"
        color="primary"
        tile
      >
    <v-container
        fill-height
        fluid
        class="py-0"
      >
        <!-- <v-row class="fill-height"> -->
        <v-row>
          <v-col justify="center" align="center" class="ml-4">
            <h1
              class="mb-2 white--text big-text text-uppercase">About Us</h1>
              <v-divider class="mb-4" color="white" width="200"> </v-divider>
            <p
              class="white--text mb-0" :style="$vuetify.breakpoint.mdAndUp ? 'font-size: 1.2em': ''">Decades of priceless experience growing people, and ministries can be found<br> between Doug and Joyce Feil, the leaders of Feil Counseling and Consulting.<br> We can help clients, missionaries, business and church leaders experience lasting growth.</p>
          </v-col>
        </v-row>
      </v-container>
       </v-sheet>
    <v-container class="mt-8">
      <v-row>
      <v-col cols="12">
      <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 12 : 12">
        <v-row>
      <v-col cols="12">
        <h1 class="big-text">Doug Feil</h1>
        <h3 class="mb-3">Licensed Professional Counselor</h3>
      </v-col>
        <v-col
          :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3"
          :align="$vuetify.breakpoint.mdAndDown ? 'center' : ''"
        >
          <v-card v-if="!isMobile()" flat class="pr-0">
            <v-img
              src="/static/dougfeilverticalspring_3.jpg"
              width="300"
              alt="doug feil profile photo"
            />
          </v-card>
          <v-card v-else flat class="pr-0">
            <v-img
              src="/static/dougfeilspring.jpg"
              width="300"
              alt="doug feil profile photo"
            />
          </v-card>
          
        </v-col>
 <v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical></v-divider>
        <v-col
          class="px-0"
          :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12"
        >
          <v-card
            flat
          >
          <v-card-text>
           <h3>Education</h3>
              University of California, Chico B.A. Psychology, 1976<br>
              University of California, San Jose M.S. Psychology Counseling, 1984
            </v-card-text>
          <v-card-text>
           <h3>Professional Associations</h3>
              Licensed Professional Counselor #787 Colorado<br>
              Licensed Pastor, Evangelical Church Alliance
            </v-card-text>
          <v-card-text>
           <h3>Biography</h3>
              I was born and raised in San Jose, CA.  I grew up in a Christian home (Mennonite Brethren background), where my father was a carpet layer until age 70.
              <br>
              <br>
              After completing my bachelors, I served for 5 years as a missionary with the Mennonite Brethren Missions Board.  I lived 1 year in Costa Rica learning Spanish and acculturating and then spent 4 years in Uruguay as a pastor to a church plant.  I met my wife Joyce in Montevideo, where she was serving with a different organization.
              <br>
              <br>
              While doing the missionary pastor work in Uruguay, I became more passionate about the need for professional Christian  counseling. I repeatedly encountered complex personal and relational difficulties in those I was there to shepherd and felt frustrated that at the time I had limited help to offer them.  This ignited a passion to learn all I could through counseling training to offer help and solutions to their real life complex issues.  That continues to be my driving passion and joy in working with each client I meet with and seek to help.
              <br>
              <br>
              We returned to the US in 1981 and married July 31, 1982.  I completed the Masters degree in 1984.  Joyce served as an assistant to the Communications Director with OCI for 2 years. We moved to Colorado in 1984.  We were blessed with 3 daughters – Naomi, Rachel and Caryn.
              <br>
              <br>
              <strong>My cross-cultural missions and ministry experiences helped build a passion in me to seek to truly help hurting people, so I pursued counseling as the best way for me to do that.</strong>
              <br>
              <br>
              I began my professional counseling career in Colorado in 1984.  I worked for 5 years with Christian Counseling and Guidance Services and then in January 1989 moved to Southwest Counseling Associates (SCA), where I served as a Clinical Therapist.  I also served as the Executive Director for 20 years.  I left SCA as of June 1, 2021.
              <br>
              <br>
              During those 32 years at SCA, Joyce and I, along with several other SCA leaders, launched Missionary Care Services, International (MCS).  We sought to provide professional counseling, consulting and training to the missions community – MCS was first launched in December, 1990 and continues to the present. <strong>Joyce and I met on the mission field and have loved missions ever since.  We have great joy in caring for the amazing global workers serving locally and globally.</strong>
              <br>
              <br>
              We have attended Foothills Bible Church (Littleton, CO) since 1993.  I have served in the church in a number of volunteer capacities up to the present.
              <br>
              <br>
              I am blessed to be married to my best friend, love of my life and ministry partner Joyce for over 39 years.  We love many shared hobbies and interests including traveling, fishing, biking, camping and spending time with our daughters and son-in-laws.
            </v-card-text>
          </v-card>
        </v-col>
        </v-row>
  <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 12 : 12">
        <v-row>
      <v-col cols="12">
        <h2 class="big-text">Joyce Feil</h2>
        <h3 class="mb-3">Member Care Specialist</h3>
      </v-col>
        <v-col
          :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3"
          :align="$vuetify.breakpoint.mdAndDown ? 'center' : ''"
        >
          <v-card v-if="!isMobile()" flat class="pr-0">
            <v-img
              src="/static/joycepicvertical_spring.jpg"
              width="300"
              alt="joyce feil profile photo"
            />
          </v-card>
          <v-card v-else flat class="pr-0">
            <v-img
              src="/static/joycepic.jpg"
              width="300"
              alt="joyce feil profile photo"
            />
          </v-card>
          
        </v-col>
 <v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical></v-divider>
        <v-col
          class="px-0"
          :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12"
        >
          <v-card
            flat
          >
          <v-card-text class="mb-4">
           <h3>Biography</h3>
              I grew up in Curitiba, Brazil where my parents served as missionaries for 10 years.  They served as directors of a children's home for over 70 children.
              <br>
              <br>  
              My family returned to the US when I was the vulnerable age of 13. Re-entry was very difficult for us and our church and sending organization were inexperienced in the importance of member care.
              <br>
              <br>  
              Our difficult family experience <strong>inspired in me a passion to change that culture in churches and organizations.</strong>  
              <br>
              <br>
              After Bible College I returned to Brazil and served with Wycliffe Bible Translators in Brasilia.  I later served for three and a half years on the ministry ship called the Doulos with Operation Mobilization.  The Doulos spent two years in Latin America and it was when it docked in Montevideo, Uruguay that I met my husband Doug who was serving there as a missionary pastor.  
              <br>
              <br>
              We were later married in 1982 in San Jose, California. We moved to Colorado in 1984.  We have 3 daughters and in the early years I served as an active volunteer in missions at church.  Once our daughters were all in school full time I was offered a staff position at Foothills Bible Church as the Global Outreach Director.
              <br>
              <br>
              I retired from that role on December 15, 2020 after 23 years of dedicated and fruitful global impact.
              <br>
              <br>
              <strong>I infused in our global strategy a significant emphasis of member care, investing not just in our workers ministries but also an emphasis on caring for the whole person including their children through every season of the missionary life cycle.</strong>
              <br>
              <br>
              I'm excited to now work more closely with Doug to offer training and consulting for workers, churches and organizations to help them create  integrated global strategies, effective member care to the whole person and other critical services for greater global impact.
            </v-card-text>
          </v-card>
        </v-col>
        </v-row>
      </v-col>
      </v-row>
      </v-col>
      </v-row>
     
      
    </v-container>
    <!-- <MidImage :bartype="'happy'"/> -->
    <ContactBar />
  </section>
</template>

<script>
  export default {
    metaInfo() {
        return {
            title: 'About Feil Counseling and Consulting',
            titleTemplate: 'About | Feil Counseling and Consulting',
            htmlAttrs: {
              lang: 'en-US'
            },
            meta: [
              { charset: 'utf-8' },
              { name: 'description', content: 'Doug Feil, Licensed Professional Counselor'},
              {property: 'og:site_name', content: 'Feil Counseling and Consulting'},
              {property: 'og:image', content: '/static/feil_logo_vertical.png'},
              {property: 'twitter:image:src', content: '/static/feil_logo_vertical.png'}
            ]
        };
    },
    components: {
      Heading: () => import('@/components/Heading'),
      Testimonial: () => import('@/components/Testimonial'),
      GetAQuote: () => import('@/components/GetAQuote'),
      ContactBar: () => import('@/components/ContactBar'),
      MidImage: () => import('@/components/MidImage'),
    },

    data: () => ({
      testimonial: {
        blurb: 'With Alpha Construction managing all of our customer-facing services and ASCC, helping us monitor and understand building performance, Abocado is in great shape to create and release new products.',
        person: 'Jay Oakrson',
        title: 'CEO Abocado'
      }
    }),
    methods: {
       isMobile() {
          if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
          } else {
            return false
          }
        }
    }
  }
</script>
<style scoped>
.big-text {
    font-size: 3em;
    /* font-family: "EB Garamond", serif !important */
}
.v-h1 {
    font-size: 3em;
}
.midbar-text {
    font-size: 1.5em;
}
.h3 {
    font-size: 1.5em !important;
}
</style>
